<script setup lang="ts">
import type { ComputedRef } from 'vue';
import type { Notification } from '@shopware-pwa/composables-next';
import SharedAlert from '~/components/shared/SharedAlert.vue';

defineEmits<{
    (e: 'click:close', id: number): void;
}>();

const props = defineProps<{
    notification: Notification;
}>();

const colorCssMap = {
    info: 'brand-info',
    success: 'brand-success',
    warning: 'brand-warning',
    danger: 'brand-danger',
};

const { t, te } = useI18n();

const themeTypeColor: ComputedRef<string> = computed(() => colorCssMap[props.notification.type] || 'brand-info');

const heading = computed(() => {
    let toConvert: string = props.notification.type;
    if (toConvert === 'danger') toConvert = 'error';

    if (te(`notifications.${toConvert}`)) {
        return t(`notifications.${toConvert}`);
    }

    return toConvert.charAt(0).toUpperCase() + toConvert.slice(1);
});
</script>
<template>
    <div :id="`toast-${notification.id}`">
        <SharedAlert
            class="mb-4 max-w-xs"
            :color="themeTypeColor"
            :close-target="notification.id"
            @click:close="$emit('click:close', notification.id)"
        >
            <template #heading>
                {{ heading }}
            </template>
            <template #message>
                <div v-html="notification.message"></div>
            </template>
        </SharedAlert>
    </div>
</template>
